import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';

import parseLocationForURL from 'utils/parseLocationForURL';
import addBookmark from 'actions/addBookmark';

export class Home extends Component {
  componentDidMount() {
    const { history, location, addBookmark } = this.props;
    const url = parseLocationForURL(location);
    if (url) {
      addBookmark({ url: url.href }).then(action => {
        if (action.error) {
          console.error(action.error);
        }
        history.push('/');
      });
    }
  }
  render() {
    const { bookmarks } = this.props;
    return (
      <div className="Home">
        <h2 className="heading">Bookmarked ({bookmarks.records.length})</h2>
        {bookmarks.records.length > 0 && (
          <ul>
            {bookmarks.records.map(bookmark => (
              <li key={bookmark.id}>
                <div className="card">
                  {bookmark.title && (
                    <>
                      <span className="title">{bookmark.title}</span>
                    </>
                  )}
                  <a
                    href={bookmark.url}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    {bookmark.url}
                  </a>
                  <div className="meta-group">
                    <span className="meta-text">
                      <span className="text-label">Added</span>{' '}
                      {moment(bookmark.createdOn).fromNow()}
                    </span>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        )}
      </div>
    );
  }
}

Home.propTypes = {
  addBookmark: PropTypes.func.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string.isRequired,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  bookmarks: PropTypes.shape({
    records: PropTypes.arrayOf(
      PropTypes.shape({
        url: PropTypes.string,
      }),
    ),
  }),
};

Home.defaultProps = {
  bookmarks: {
    records: [],
  },
};

const mapStateToProps = state => ({
  bookmarks: state.bookmarks,
});

const mapDispatchToProps = {
  addBookmark,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Home);
