/**
 * Parses the given location object and extracts the potential URL.
 * Returns null if a URL is not found, otherwise returns the URL.
 * @param {object} location
 * @param {string} location.pathname
 * @param {string} location.search
 */
const parseLocationForURL = (location = {}) => {
  const { pathname = '', search = '' } = location;
  if (!pathname || pathname === '/') {
    return null;
  }

  let url;

  try {
    const urlString = `${pathname.substr(1)}${search}`;
    url = new URL(urlString);
  } catch (error) {
    return null;
  }

  return url;
};

export default parseLocationForURL;
