import kstore from 'kstore';
import {
  ADD_BOOKMARK_REQUEST,
  ADD_BOOKMARK_SUCCESS,
  ADD_BOOKMARK_FAILURE,
} from 'constants/actionTypes';

const initialState = {
  records: kstore.bookmarks,
  adding: true,
  errors: {
    adding: null,
  },
};

const bookmarksReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_BOOKMARK_REQUEST:
      return {
        ...state,
        adding: true,
        errors: {
          adding: null,
        },
      };
    case ADD_BOOKMARK_SUCCESS:
      return {
        ...state,
        records: [action.bookmark, ...state.records],
        adding: false,
      };
    case ADD_BOOKMARK_FAILURE:
      return {
        ...state,
        adding: false,
        error: {
          adding: action.error,
        },
      };
    default:
      return {
        ...state,
      };
  }
};

export default bookmarksReducer;
