import {
  APP_INIT_REQUEST,
  APP_INIT_SUCCESS,
  APP_INIT_FAILURE,
} from '../constants/actionTypes';

const doInit = () =>
  new Promise((resolve, reject) => {
    try {
      resolve();
    } catch (err) {
      reject(err);
    }
  });

export const initRequest = () => ({
  type: APP_INIT_REQUEST,
});

export const initSuccess = () => ({
  type: APP_INIT_SUCCESS,
});

export const initFailure = error => ({
  type: APP_INIT_FAILURE,
  error,
});

const initialize = () => async dispatch => {
  dispatch(initRequest());
  try {
    await doInit();
    return dispatch(initSuccess());
  } catch (err) {
    return dispatch(initFailure(err));
  }
};

export default initialize;
