import React, { Component } from 'react';

export class Login extends Component {
  render() {
    return (
      <div className="Login">
        <h1>redux-base</h1>
        <p>
          This is the <strong>login</strong> component.
        </p>
      </div>
    );
  }
}

export default Login;
