import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';

import store from './store';
import ScrollRestore from './components/ScrollRestore';
import ErrorBoundary from './components/ErrorBoundary';
import App from './App';

import 'styles';

ReactDOM.render(
  <Provider store={store}>
    <ErrorBoundary>
      <Router>
        <ScrollRestore>
          <App />
        </ScrollRestore>
      </Router>
    </ErrorBoundary>
  </Provider>,
  document.getElementById('root'),
);

serviceWorker.register();
