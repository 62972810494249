import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import Routes from './Routes';
import initialize from 'actions/initialize';

export class App extends Component {
  componentDidMount() {
    const { initialize } = this.props;
    initialize();
  }

  render() {
    const { initializing } = this.props;
    if (initializing) {
      return <p>Initializing...</p>;
    }
    return (
      <div className="App">
        <div className="header">
          <h1 className="title">
            <Link to="/">
              <span>KBase</span>
            </Link>
          </h1>
        </div>
        <Routes />
        <footer>
          <small>{process.env.REACT_APP_VERSION || 'v.0.0.0'}</small>
        </footer>
      </div>
    );
  }
}

App.propTypes = {
  initialize: PropTypes.func.isRequired,
  initializing: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  initializing: state.app.initializing,
});

const mapDispatchToProps = {
  initialize,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(App),
);
