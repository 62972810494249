import {
  ADD_BOOKMARK_REQUEST,
  ADD_BOOKMARK_SUCCESS,
  ADD_BOOKMARK_FAILURE,
} from '../constants/actionTypes';
import kstore from 'kstore';

export const addBookmarkRequest = () => ({
  type: ADD_BOOKMARK_REQUEST,
});

export const addBookmarkSuccess = bookmark => ({
  type: ADD_BOOKMARK_SUCCESS,
  bookmark,
});

export const addBookmarkFailure = error => ({
  type: ADD_BOOKMARK_FAILURE,
  error,
});

const addBookmark = ({ title, text, url }) => async dispatch => {
  dispatch(addBookmarkRequest());
  const possibleURL = url || text || title || '';
  try {
    const found = kstore.bookmarks.find(
      bookmark => bookmark.url === possibleURL,
    );
    if (found) {
      console.log('Found repeat');
      return dispatch(
        addBookmarkFailure(new Error('The bookmark already exists.')),
      );
    }
    const bookmark = await kstore.saveBookmark(title, possibleURL);
    return dispatch(addBookmarkSuccess(bookmark));
  } catch (err) {
    return dispatch(addBookmarkFailure(err));
  }
};

export default addBookmark;
