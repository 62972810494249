/**
 * This module acts as the database layer for version 0 of the KBase app.
 * Eventually, most of the functionality here will be persisted to the
 * database via the kbase API.
 */

/**
 * Creates a bookmark from the given URL object.
 * @param {URL} url
 */
export const createBookmark = (title, url) => {
  return {
    id: url,
    createdOn: Date.now(),
    lastSeenOn: null,
    archived: false,
    title: title || null,
    url: url,
  };
};

/**
 * Persists application data to localStorage.
 */
export class KStore {
  KEY = '@kbase/app';

  store = {
    bookmarks: [],
  };

  constructor() {
    const store = localStorage.getItem(this.KEY);
    if (store) {
      this.store = JSON.parse(store);
    } else {
      this.save();
    }
  }

  get bookmarks() {
    return this.store.bookmarks;
  }

  set bookmarks(bookmarks) {
    this.store.bookmarks = bookmarks;
    this.save();
  }

  saveBookmark(title, url) {
    return new Promise((resolve, reject) => {
      try {
        const bookmark = createBookmark(title, url);
        this.bookmarks = [bookmark, ...this.bookmarks];
        resolve(bookmark);
      } catch (error) {
        reject(error);
      }
    });
  }

  save() {
    localStorage.setItem(this.KEY, JSON.stringify(this.store));
  }

  remove() {
    localStorage.removeItem(this.KEY);
  }
}

// NOTE Returns a singleton instance by default.
export default new KStore();
