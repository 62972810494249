import {
  APP_INIT_REQUEST,
  APP_INIT_SUCCESS,
  APP_INIT_FAILURE,
} from 'constants/actionTypes';

const initialState = {
  initializing: true,
};

const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case APP_INIT_REQUEST:
      return {
        ...state,
        initializing: true,
      };
    case APP_INIT_SUCCESS:
      return {
        ...state,
        initializing: false,
      };
    case APP_INIT_FAILURE:
      return {
        ...state,
        initializing: false,
        error: action.error,
      };
    default:
      return {
        ...state,
      };
  }
};

export default appReducer;
