import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import addBookmark from 'actions/addBookmark';

export const Shared = ({ location, history, addBookmark }) => {
  const { search } = location;
  const { title, text, url } = queryString.parse(search);
  addBookmark({ title, text, url }).then(action => {
    if (action.error) {
      console.error(action.error);
    }
    history.push(`/`);
  });
  return null;
};

Shared.propTypes = {
  addBookmark: PropTypes.func.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string.isRequired,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

const mapDispatchToProps = {
  addBookmark,
};

export default connect(
  null,
  mapDispatchToProps,
)(Shared);
