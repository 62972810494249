import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, Redirect } from 'react-router-dom';

import Home from 'components/Home';
import Login from 'components/Login';
import Shared from 'components/Shared';
// import Protected from './components/Protected';
// import NoMatch from './components/NoMatch';

// TODO Replace with a check to authorize/authentication.
const isAuthed = () => false;

export const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      isAuthed() ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: '/login',
            state: { from: props.location },
          }}
        />
      )
    }
  />
);

PrivateRoute.propTypes = {
  component: PropTypes.element.isRequired,
  location: PropTypes.string.isRequired,
};

export const Routes = () => (
  <Switch>
    <Route path="/shared" exact component={Shared} />
    <Route path="/" component={Home} />
    <Route path="/login" exact component={Login} />
    {/* <PrivateRoute path="/protected" component={Protected} /> */}
    {/* <Route component={NoMatch} /> */}
  </Switch>
);

export default Routes;
